import React from 'react'
import './About.css'


const About = () => {
  return (
    <div id="About" className='about-container'>

      <div className='about-headline'>
        <h2>What we do</h2>
        <p>As a HubSpot Partner, PMD Solutions offers more than just professional services; we empower your business across HubSpot's Sales, Service, and CMS hubs. By truly understanding your unique needs, we create tailored solutions that drive your business forward.</p>
      </div>


      <div className='box-container'>
        <div className='about-square-container'>

          <div>
            <span>Our vision</span>
            <p>Advancing into the future, our vision is to establish a higher level for realization in the consulting environment. </p>
          </div>
          <div>
            <span>Our Commitment</span>
            <p>Our focus is on delivering exceptional value to our clients, helping them uncover hidden potential and achieve their highest aspirations.  </p>
          </div>
        </div>


        <div className='about-rectangle-container'>
          <div>
            <span>Join us</span>
            <p>Our focus is on delivering exceptional value to our clients, helping them uncover hidden potential and achieve their highest aspirations.</p>
          </div>
        </div>
      </div>


    </div>
  )
}

export default About