import React from 'react'
import './Footer.css'

import pmdLogo from '../../Assets/Footer Assets/pmdLogo.svg'
import instagram from '../../Assets/Contact Assets/instagram.svg';
import facebook from '../../Assets/Contact Assets/facebook.svg';
import linkedin from '../../Assets/Contact Assets/linkedin.svg';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-logot'> 
        {/* TODO - change the classname to footer-logo */}
         <a href='#Home'><img src={pmdLogo}></img></a>
      </div>
      <ul className='footer-navigation'>

        <a href='#About'><li>About</li></a>
        <a href='#Services'><li>Services</li></a>
        <a href='#Partners'><li>Partners</li></a>
        <a href='#Contact'><li>Contact Us</li></a>
        <a href='#FAQ'><li>FAQ</li></a>
      </ul>


      <ul className='footer-social-media'>
        <a href='https://www.linkedin.com/company/pmd-solutions1/'  target='_blank'><li><img src={linkedin}></img></li></a>
        <a href='https://www.facebook.com/pmdsolutions.org'  target='_blank' ><li><img src={facebook}></img></li></a>
        <a href='https://www.instagram.com/pmd_solutions_org/' target='_blank'><li><img src={instagram}></img></li></a>
   
      </ul>


    </div>
  )
}

export default Footer