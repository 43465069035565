import React from 'react';
import '../App.css';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Partners from '../components/Partners/Partners';
import Services from '../components/Services/Services';
import About from '../components/About/About';
import FAQ from '../components/FAQ/FAQ';
import Integrations from '../components/Integrations/Integrations';
import Contact from '../components/Contact/Contact';
import Footer from '../components/Footer/Footer';

const FirstPage = () => {
  return (
    <>
      <Header />
      <Hero />
      <Partners />
      <Services />
      <About />
      <FAQ />
      <Integrations />
      <Contact />
      <Footer />
    </>
  );
}

export default FirstPage;
