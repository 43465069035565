
import React, { useEffect, useRef } from 'react';
import highlight1 from '../../Assets/Hero Assets/Highlight1.svg'
import highlight2 from '../../Assets/Hero Assets/Highlight2.svg'
import './Hero.css'
import lottie from 'lottie-web';
import animationData from '../../Assets/Hero Assets/bendy-customer-segmentation-and-target-audience.json';
const Hero = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    // Ensure animationContainer.current is valid and animationData is loaded
    if (!animationContainer.current || !animationData) return;

    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy(); // Clean up animation when component unmounts
    };
  }, [animationData]); // Dependency on animationData ensures animation reloads if data changes

 
  return (
    <div className='hero-container'>

    <div className='hero-section-text-part'>
        <div className='services-container'>
            <div>Hubspot</div>
            <div>CRM</div>
            <div>Audit</div>
            <div>Automation</div>
        </div>

        <div className='hero-section-main-text-part'>
    <h1>
        Transform Business With  &nbsp; 
        <span className="highlight-wrapper">
            <span className="highlight-text">Innovation</span> &nbsp; 
            <img src={highlight1} alt="Highlight" className="highlight-svg" />
        </span> 
        and 
        <span className="highlight-wrapper"> &nbsp; 
            <span className="highlight-text">Expertise</span>
            <img src={highlight2} alt="Highlight" className="highlight-svg" />
        </span>
    </h1>
    <p>We are a full-service HubSpot implementation partner serving customers worldwide.
Increase Sales Revenue with HubSpot CRM Generate Better Leads, Boost Traffic, Enhance Sales, and Improve Visibility.</p>
    <a href="https://meetings.hubspot.com/gohar-gh-971/pmdsolutions" target="_blank" ><button>Talk to Sales</button></a>
</div>


    </div>

    <div className='hero-section-animation-part' ref={animationContainer}>
    
    </div>

    </div>
  )
}

export default Hero