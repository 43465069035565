import React from 'react';
import './Partners.css';

import COWO from '../../Assets/Partners Assets/COWO.png';
import Dexatel from '../../Assets/Partners Assets/Dexatel.png';
import LightAffect from '../../Assets/Partners Assets/Light Affect.png';
import MiterraHome from '../../Assets/Partners Assets/Miterra Home.png';
import Prelaunch from '../../Assets/Partners Assets/Prelaunch.com.png';
import TaConsulting from '../../Assets/Partners Assets/TA Consulting.png';
import CrowdfundingFormula from '../../Assets/Partners Assets/The Crowdfunding Formula.png';
import Snack from '../../Assets/Partners Assets/Snack.png';
import UATE from '../../Assets/Partners Assets/uate.png';
import Gadgetflow from '../../Assets/Partners Assets/Gadgetflow.png';

const partnersList = [
  { id: 1, src: COWO, alt: 'COWO' },
  { id: 2, src: Dexatel, alt: 'Dexatel' },
  { id: 3, src: LightAffect, alt: 'Light Affect' },
  { id: 4, src: MiterraHome, alt: 'Miterra Home' },
  { id: 5, src: Prelaunch, alt: 'Prelaunch.com' },
  { id: 6, src: UATE, alt: 'UATE' },
  { id: 7, src: Gadgetflow, alt: 'GadgetFlow' },
  { id: 8, src: TaConsulting, alt: 'TA Consulting' },
  { id: 9, src: CrowdfundingFormula, alt: 'The Crowdfunding Formula' },
  { id: 10, src: Snack, alt: 'Snack' }
];

const Partners = () => {
  const extendedPartnersList = [...partnersList, ...partnersList, ...partnersList];

  return (
    <div id='Partners' className='partners-container'>
      <div className='partners-list'>
        {extendedPartnersList.map((partner, index) => (
          <img key={`${partner.id}-${index}`} className='partner-item' src={partner.src} alt={partner.alt} />
        ))}
      </div>
    </div>
  );
};

export default Partners;
