import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import './NotFound.css'
import lottie from 'lottie-web'
import animationData from '../../Assets/NotFound/404 not found page.json'

import checkCircle from '../../Assets/Contact Assets/check-circle.svg'
import mail from '../../Assets/Contact Assets/mail.svg'
import mail2 from '../../Assets/Contact Assets/mail2.svg'
import user from '../../Assets/Contact Assets/user.svg'
import instagram from '../../Assets/Contact Assets/instagram.svg'
import facebook from '../../Assets/Contact Assets/facebook.svg'
import linkedin from '../../Assets/Contact Assets/linkedin.svg'
import mapPin from '../../Assets/Contact Assets/map-pin.svg'
import phone from '../../Assets/Contact Assets/phone.svg'

const NotFound = () => {
  const animationContainer = useRef(null)

  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')

  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault() // Prevent default form submission

    // Check for empty fields
    if (!fullName || !email || !phoneNumber || !message) {
      setError('All fields are required.')
      return
    }

    // Log the form data TODO - remove console logs
    // console.log('Full Name:', fullName);
    // console.log('Email:', email);
    // console.log('Phone Number:', phoneNumber);
    // console.log('Message:', message);

    // Mark the form as submitted
    setSubmitted(true)

    // Clear form fields and error message
    setFullName('')
    setEmail('')
    setPhoneNumber('')
    setMessage('')
    setError('')
  }

  // Function to handle input for phone number, allowing only numbers and +
  const handlePhoneNumberChange = (event) => {
    let { value } = event.target

    // Ensure only numbers and a leading +
    value = value.replace(/[^0-9+]/g, '')

    // Automatically prepend + if not already there
    if (!value.startsWith('+')) {
      value = '+' + value
    }

    setPhoneNumber(value)
  }

  useEffect(() => {
    // Ensure animationContainer.current is valid and animationData is loaded
    if (!animationContainer.current || !animationData) return

    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    })

    return () => {
      anim.destroy() // Clean up animation when component unmounts
    }
  }, [animationData]) // Dependency on animationData ensures animation reloads if data changes

  return (
    <div className='not-found'>
      <div  ref={animationContainer} className='not-found-animation'></div>
      <div className='not-found-form'>
        <div className="contact-main-text-part">
          <Link className="contact-btn" to="/" style={{ padding: '10px', display:"inline-block", width:"fit-content" }}>
            Return to home page
          </Link>
          <p>Or just create an inquiry</p>
        </div>

        {!submitted ? (
          <form className="contact-form" onSubmit={handleSubmit}>
            {error && <p className="error">{error}</p>}
            <div className="input-container">
              <input
                type="text"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <img src={user} alt="user icon" className="input-icon" />
            </div>
            <div className="input-container">
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <img src={mail} alt="mail icon" className="input-icon" />
            </div>
            <div className="input-container">
              <input
                type="tel"
                placeholder="Phone Number"
                id="phone-input"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <div className="input-container">
              <textarea
                id="textarea-input"
                placeholder="How can we help you?"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button className="submit-btn" type="submit">
              Send
            </button>
          </form>
        ) : (
          <div className="successed-message">
            <img src={checkCircle} alt="Success" />
            <h2>Thank you for your message</h2>
            <p>
              Our team will review your message and we will contact you as soon
              as possible.
            </p>
            <button
              className="successed-btn"
              onClick={() => setSubmitted(false)}
            >
              Send another message
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotFound
