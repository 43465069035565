import React from 'react'
import '../App.css';

import Header from '../components/Header/Header';
import NotFoundContainer from '../components/NotFound/NotFound';
import Footer from '../components/Footer/Footer';

const NotFound = () => {
  return (
    <div className='div'>

      <Header></Header>
      
      <NotFoundContainer></NotFoundContainer>
      <Footer></Footer>

    </div>
  )
}

export default NotFound