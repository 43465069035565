import React, { useState } from 'react';
import './Contact.css';

import checkCircle from '../../Assets/Contact Assets/check-circle.svg'
import mail from '../../Assets/Contact Assets/mail.svg';
import mail2 from '../../Assets/Contact Assets/mail2.svg';
import user from '../../Assets/Contact Assets/user.svg';
import instagram from '../../Assets/Contact Assets/instagram.svg';
import facebook from '../../Assets/Contact Assets/facebook.svg';
import linkedin from '../../Assets/Contact Assets/linkedin.svg';
import mapPin from '../../Assets/Contact Assets/map-pin.svg';
import phone from '../../Assets/Contact Assets/phone.svg';

const Contact = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false); 

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    // Check for empty fields
    if (!fullName || !email || !phoneNumber || !message) {
      setError('All fields are required.');
      return;
    }

    // Log the form data TODO - remove console logs
    // console.log('Full Name:', fullName);
    // console.log('Email:', email);
    // console.log('Phone Number:', phoneNumber);
    // console.log('Message:', message);

    // Mark the form as submitted
    setSubmitted(true);

    // Clear form fields and error message
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
    setError('');
  };

  // Function to handle input for phone number, allowing only numbers and +
const handlePhoneNumberChange = (event) => {
  let { value } = event.target;

  // Ensure only numbers and a leading +
  value = value.replace(/[^0-9+]/g, '');

  // Automatically prepend + if not already there
  if (!value.startsWith('+')) {
    value = '+' + value;
  }

  setPhoneNumber(value);
};

//TODO - add validation for email, change input type from email to text

  return (
    <div id='Contact' className='contact-container'>
      <div className='contact-headline'>
        <div className='contact-main-text-part'>
          <h2>Contact Us</h2>
          <p>Leave us a message to say hello, start a project, or request a proposal. We’re excited to hear from you!</p>
        </div>

        <div className='contact-social-media'>
          <p>Find us on</p>
          <ul>
            <a href='https://www.linkedin.com/company/pmd-solutions1/' target='_blank' rel='noopener noreferrer'><li><img src={linkedin} alt="LinkedIn" /></li></a>
            <a href='https://www.facebook.com/pmdsolutions.org' target='_blank' rel='noopener noreferrer'><li><img src={facebook} alt="Facebook" /></li></a>
            <a href='https://www.instagram.com/pmd_solutions_org/' target='_blank' rel='noopener noreferrer'><li><img src={instagram} alt="Instagram" /></li></a>
          </ul>
        </div>

        <div className='contact-addresses'>
          <ul>
            <li> <img src={phone} alt="Phone" /> <span>+374 94 282010</span></li>
            <li> <img src={phone} alt="Phone" /> <span>+1 315 924-7049</span></li>
            <li> <img src={mail2} alt="Mail" /> <span>info@pmdsolutions.org</span></li>
            <li> <img src={mapPin} alt="Map Pin" /> <span>70/8 Charents St, Yerevan, Armenia</span></li>
          </ul>
        </div>
      </div>

      {!submitted ? (
        <form className='contact-form' onSubmit={handleSubmit}>
          {error && <p className="error">{error}</p>}
          <div className='input-container'>
            <input
              type='text'
              placeholder='Full Name'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <img src={user} alt='user icon' className='input-icon' />
          </div>
          <div className='input-container'>
            <input
              type='email'
              placeholder='Your Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <img src={mail} alt='mail icon' className='input-icon' />
          </div>
          <div className='input-container'>
            <input
              type='tel'
              placeholder='Phone Number'
              id='phone-input'
              value={phoneNumber}
              onChange={handlePhoneNumberChange} 
            />
          </div>
          <div className='input-container'>
            <textarea
              id='textarea-input'
              placeholder='How can we help you?'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button className='submit-btn' type='submit'>Send</button>
        </form>
      ) : (
        <div className='successed-message'>
          <img src={checkCircle} alt="Success" />
          <h2>Thank you for your message</h2>
          <p>Our team will review your message and we will contact you as soon as possible.</p>
          <button className='successed-btn' onClick={() => setSubmitted(false)}>Send another message</button>
        </div>
      )}


      

    </div>
  );
};

export default Contact;
