import React, { useState } from 'react';
import './FAQ.css';

import plus from '../../Assets/FAQ Assets/plus.svg';
import minus from '../../Assets/FAQ Assets/minus.svg';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: 'What is PMD Solutions?',
      answer:
        'PMD Solutions specializes in business process management and development, leveraging HubSpot to enhance business CRM processes. We provide tailored solutions to help large businesses streamline their operations, refine strategies, and achieve greater efficiency.',
    },
    {
      question: 'How does PMD Solutions work?',
      answer:
        'At PMD Solutions, we genuinely care about your business. We start by getting to know your unique needs through detailed consultations. Then, we craft customized strategies using HubSpot\'s Sales, Service, and CMS hubs. Our team handles the implementation and integration smoothly, ensuring everything works seamlessly. We also provide thorough training and ongoing support, always optimizing and updating to keep your business thriving.',
    },
    {
      question: 'What services do you offer?',
      answer:
        'We offer a comprehensive suite of services including business process management, strategic planning, transition management, and expert consulting to address the unique challenges of large businesses.',
    },
    {
      question: 'Who can benefit from PMD Solutions?',
      answer:
        'Our services are designed for large businesses seeking to optimize their processes, enhance productivity, and achieve strategic goals. We also cater to digital creators who sell products and grow their business through content marketing.',
    },
    {
      question: 'What makes PMD Solutions different from other consulting agencies?',
      answer:
        'At PMD Solutions, we blend elegance with expertise and innovation with importance. Our use of modern technologies and HubSpot CRM tools ensures that we provide cutting-edge solutions tailored to the unique needs of each business. We are dedicated partners in success, committed to delivering excellence.',
    },
    {
      question: 'What is the typical duration of an integration project?',
      answer:
        'Since we customize our approach based on your business\'s unique needs and complexities, the duration of each integration project can vary. However, most integrations typically take between a few weeks and two months. Our aim is to ensure a seamless integration that aligns with your operational timelines and objectives.',
    },
    {
      question: 'How does PMD Solutions maintain data security throughout the integration process?',
      answer:
        'We prioritize your data security throughout the integration process. Our team follows rigorous data security protocols and compliance standards to ensure your information is safeguarded at every stage.',
    },
    {
      question: 'What types of systems have you integrated with HubSpot before (e.g., CRM, ERP, CMS, custom applications)?',
      answer:
        'Our experience covers a wide range of integrations, including syncing HubSpot with various CRM platforms, connecting it with ERP systems for better resource management, developing custom API solutions, and integrating additional communication tools for a cohesive customer experience. We can also assist with configuring native integrations from the HubSpot App Marketplace.',
    },
    {
      question: 'How can I contact PMD Solutions for support or more information?',
      answer:
        'For support or more information, please visit our Contact Us section on the website. You can also reach out to us via email or phone, and our customer service team will be happy to assist you.',
    },
  ];

  return (
    <div id='FAQ' className='faq-container'>
      <div className='faq-headline'>
        <span>Frequently Asked <h2>Questions</h2></span>
        <p>Here are the most often asked questions.</p>
      </div>

      <div className='questions-container'>
        {faqItems.map((item, index) => (
          <div key={index} className={`questions-element-container ${openIndex === index ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
            <div className="questions-element-header">
              <span>{item.question}</span>
              <button onClick={(e) => { e.stopPropagation(); toggleFAQ(index); }}>
                <img src={openIndex === index ? minus : plus} alt={openIndex === index ? 'minus' : 'plus'} />
              </button>
            </div>
            {openIndex === index && <p>{item.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
