import React from 'react';
import './Integrations.css';

import armsms from '../../Assets/Integrations Assets/armsms.png';
import dexatel from '../../Assets/Integrations Assets/dexatel.png';
import googleCalendar from '../../Assets/Integrations Assets/google calendar.png';
import googleSheet from '../../Assets/Integrations Assets/google-sheets.avif';
import hubspot from '../../Assets/Integrations Assets/hubspot.png';
import easypay from '../../Assets/Integrations Assets/easypay.png';
import gmail from '../../Assets/Integrations Assets/gmail.jpg';
import googleMeet from '../../Assets/Integrations Assets/google meet.webp';
import googleDocs from '../../Assets/Integrations Assets/google_docs.avif';
import idram from '../../Assets/Integrations Assets/idram.png';
import lookerStudio from '../../Assets/Integrations Assets/looker studio.png';
import shopify from '../../Assets/Integrations Assets/shopify.png';
import slack from '../../Assets/Integrations Assets/slack.png';
import telcell from '../../Assets/Integrations Assets/telcell.png';
import tilda from '../../Assets/Integrations Assets/tilda.png';
import ucom from '../../Assets/Integrations Assets/ucom.png';
import vivacell from '../../Assets/Integrations Assets/vivacell.jpeg';
import zapier from '../../Assets/Integrations Assets/zapier.png';
import intuit from '../../Assets/Integrations Assets/intuit.jpg';
import zoom from '../../Assets/Integrations Assets/zoom.jpeg';
import airtable from '../../Assets/Integrations Assets/airtable.png';
import calendly from '../../Assets/Integrations Assets/calendly.webp';
import nikita from '../../Assets/Integrations Assets/nikita.jpg';
import stripe from '../../Assets/Integrations Assets/stripe.png';




const Integrations = () => {
  return (
    <div className='integrations-container'>
      <h2>Over 20+ Integrations</h2>

      <div className='integrations-companies-container'>
        <div><img src={armsms} alt='Armsms' title='Armsms' /></div>
        <div><img src={dexatel} alt='Dexatel' title='Dexatel' /></div>
        <div><img src={zapier} alt='Zapier' title='Zapier' /></div>
        <div><img src={googleCalendar} alt='Google Calendar' title='Google Calendar' /></div>
        <div><img src={googleSheet} alt='Google Sheets' title='Google Sheets' /></div>
        <div><img src={hubspot} alt='HubSpot' title='HubSpot' /></div>
        <div><img src={stripe} alt='Stripe' title='Stripe' /></div>
        <div><img src={intuit} alt='Intuit' title='Intuit' /></div>
        <div><img src={idram} alt='Idram' title='Idram' /></div>
        <div><img src={telcell} alt='Telcell' title='Telcell' /></div>
        <div><img src={easypay} alt='Easypay' title='Easypay' /></div>
        <div><img src={zoom} alt='Zoom' title='Zoom' /></div>
        <div><img src={slack} alt='Slack' title='Slack' /></div>
        <div><img src={gmail} alt='Gmail' title='Gmail' /></div>
        <div><img src={googleMeet} alt='Google Meet' title='Google Meet' /></div>
        <div><img src={googleDocs} alt='Google Docs' title='Google Docs' /></div>
        <div><img src={tilda} alt='Tilda' title='Tilda' /></div>
        <div><img src={vivacell} alt='Vivacell' title='Vivacell' /></div>
        <div><img src={ucom} alt='Ucom' title='Ucom' /></div>
        <div><img src={lookerStudio} alt='Looker Studio' title='Looker Studio' /></div>
        <div><img src={shopify} alt='Shopify' title='Shopify' /></div>
        <div><img src={calendly} alt='Calendly' title='Calendly' /></div>
        <div><img src={airtable} alt='Airtable' title='Airtable' /></div>
        <div><img src={nikita} alt='Nikita' title='Nikita' /></div>
      </div>
    </div>
  );
};

export default Integrations;
 