// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners-container {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 178px;
    padding: 32px 0;
    background-color: #F1F5F8;  /*TODO - change to variable*/
}

.partners-list {
    display: flex;
    height: 114px;
    width: 200%;
    gap: 44px;
    opacity: 44%;
    justify-content: center;
    align-items: center;
    animation: scroll 25s linear infinite;
}

.partner-item {
    flex: 0 0 auto;
    padding: 0 10px;
    max-height: 80px;
    object-fit: contain;
    width: auto;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}


@media (max-width: 768px) {
    .partners-list {
        animation: scroll 5s linear infinite;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/Partners/Partners.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,eAAe;IACf,yBAAyB,GAAG,4BAA4B;AAC5D;;AAEA;IACI,aAAa;IACb,aAAa;IACb,WAAW;IACX,SAAS;IACT,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI;QACI,wBAAwB;IAC5B;;IAEA;QACI,2BAA2B;IAC/B;AACJ;;;AAGA;IACI;QACI,oCAAoC;IACxC;;AAEJ","sourcesContent":[".partners-container {\n    display: flex;\n    overflow: hidden;\n    position: relative;\n    width: 100%;\n    height: 178px;\n    padding: 32px 0;\n    background-color: #F1F5F8;  /*TODO - change to variable*/\n}\n\n.partners-list {\n    display: flex;\n    height: 114px;\n    width: 200%;\n    gap: 44px;\n    opacity: 44%;\n    justify-content: center;\n    align-items: center;\n    animation: scroll 25s linear infinite;\n}\n\n.partner-item {\n    flex: 0 0 auto;\n    padding: 0 10px;\n    max-height: 80px;\n    object-fit: contain;\n    width: auto;\n}\n\n@keyframes scroll {\n    0% {\n        transform: translateX(0);\n    }\n\n    100% {\n        transform: translateX(-50%);\n    }\n}\n\n\n@media (max-width: 768px) {\n    .partners-list {\n        animation: scroll 5s linear infinite;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
