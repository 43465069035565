import React from 'react'
import './Services.css'

import zap from '../../Assets/Services Assets/zap.svg'
import checkmark from '../../Assets/Services Assets/check-square.svg'
import trendingUp from '../../Assets/Services Assets/trending-up.svg'
import users from '../../Assets/Services Assets/users.svg'
import settings from '../../Assets/Services Assets/settings.svg'



const Services = () => {
  return (
    <div id="Services" className='our-services-container'>

      
    <p><span>Services</span> you can get </p> 
      <div className="container">

        <div className="square">
          <div className='oval'>
            <img className='icon-img' src={zap}></img>
          </div>
          <h3>Business Process Management</h3>
          <span>
            We assist you to point out the areas of wastage and then in adopting the right strategies and methods that will ensure you achieve the best results in terms of cost and time.
          </span>
        </div>

        <div className="square">
        <div className='oval'>
            <img className='icon-img' src={checkmark}></img>
          </div>
          <h3>Strategic Planning</h3>
          <span>
          Our detailed analysis and consultation will help you fine-tune your business plans. We focus on building sustainable strategies that will fit your goals.
          </span>
        </div>


        <div className="square">
        <div className='oval'>
            <img className='icon-img' src={trendingUp}></img>
          </div>
          <h3>Transition Management</h3>
          <span>
          Transition from one stage to another without much hassle or stress. When you undergo organizational changes, you need someone to guide you through the process.
          </span>
        </div>
      </div>




      <div className="rectangle-container">
        <div className="rectangle">

        <div className='oval'>
            <img className='icon-img' src={users}></img>
          </div>
          <h3>Expert Consulting</h3>
          <span>
          Our team have significant experience and knowledge to provide you with the best solutions for your business goals and to eliminate the barriers in the way.
          </span>
        </div>
        <div className="rectangle">
        <div className='oval'>
            <img className='icon-img' src={settings}></img>
          </div>
          <h3>HubSpot Integration Services</h3>
          <span>
          Trust PMD Solutions’ HubSpot Integration Services to create and implement custom integrations as a certified HubSpot Partner. We ensure seamless connections between HubSpot and your existing systems, improving your operational workflows.
          </span>
        </div>
      </div>

    </div>
  )
}

export default Services