import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FirstPage from './Pages/FirstPage';
import NotFound from './Pages/NotFound';

const App = () => {
  return (
    <Router>
      <div className='div'>
        <Routes>
          <Route path="/" element={<FirstPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
