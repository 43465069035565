
import React, { useState } from 'react'
import './Header.css'

import pmdLogo from '../../Assets/Header Assets/pmdLogo.svg'
import menu from '../../Assets/Header Assets/menu.svg'
import close from '../../Assets/Header Assets/close.svg'


const Header = () => {

  const [isMobile, setIsMobile] = useState(false)



  return (
    <div className='header-container'>

      <a href='/#Home'> <img className="header-logo" src={pmdLogo}></img>

      </a>


      <ul id='Home' className={isMobile ? "pages-mobile" : "pages"}

        onClick={() => setIsMobile(false)}>
        <a href='/#Services'><li>Services</li></a>
        <a href='/#About'><li>About Us</li></a>
        <a href='/#Partners'><li>Partners</li></a>

        <a href='/#Contact'> <button className='mobile-contact-btn'>Contact Us</button></a>
      </ul>
      <div className='mobile-menu-icon'
        onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? <img className='mobile-menu-icon' src={close}></img> : <img className='mobile-menu-icon' src={menu}></img>}
      </div>

      <a href='/#Contact'> <button className='contact-btn'>Contact Us</button></a>


    </div>
  )
}

export default Header